<router>
{
    name: 'Resources',
}
</router>
<template lang="pug">
v-container
    v-btn.brand--text(text, :href='docsLink', target='_blank') Full InfoScan Docs
        v-icon(right) help
    v-btn.brand--text(text, :href='chatBotLink', target='_blank') Chat Help
        v-icon(right) message
    v-row
        v-col
            PortalResources
</template>
<script>

import PortalResources from '@/components/portal/resources';

export default {
    meta: {
    },
    components: {
        PortalResources
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {
        docsLink () {
            return `${this.$store.state.portalBaseUrl}docs/infoscan`;
        },
        chatBotLink () {
            return `${this.$store.state.portalBaseUrl}docs/infoscan/help-chat`;
        }
    },
    mounted () {
        window.portalresources = this;
    }
};
</script>

<style scoped>
</style>
